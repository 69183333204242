<template>
	<div class="dashboard">
		<router-view name="appbar" />
		<router-view name="drawer" />
		<router-view name="dialog" />
		<v-main>
			<Content>
				<router-view />
			</Content>
		</v-main>
	</div>
</template>

<script>
import Content from '@/components/atoms/Content'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({ me: 'user/getMe' }),
	},
	components: { Content },
	async created() {
		await this.$store.dispatch('user/me')
		this.$store.dispatch('pendencies/getInfoCards', this.me.employee_id)
	},
}
</script>

<style lang="scss" scoped>
.dashboard {
	background: #f5f5f5;
	height: 100%;
}
</style>
